import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import CTA from "./components/CTA"
import Screenshots from "./components/Screenshots"
import Changelog from "./components/Changelog"

const IndexPage = () => (
  <Layout>
    <SEO title="Linksaber | Read Later to Custom Newsletter"/>

    <Header/>

    <p className="section-title">
      Put your <span className="text-pink-500">links-to-read-later</span>&nbsp;
      into a <span className="text-blue-400">custom weekly newsletter</span>
    </p>
    <p className="section-caption italic mb-5">
      If you're an email-person, you won't forget about your links ever again
    </p>

    <CTA isAlignedLeft={true}/>

    <Screenshots/>

    <p className="section-body text-gray-400 text-center -mt-4 md:-mt-8">
      No spam - No AI™ - No suggestions - No ads<br/>
      Just your stuff, straight in your inbox
    </p>

    {/*<CTA isAlignedLeft={false}/>*/}

    <p className="section-title text-center">
      Give it a shot
    </p>
    <p className="section-body text-gray-400 text-center">
      Early users are loving it:
      <br/>400+ weekly digests sent so far.
      <br/>700+ links saved by 50+ early users.
      <br/>Get on board, you're not alone.
    </p>

    <CTA isAlignedLeft={false}/>

    <Changelog/>

    <p className="text-center text-gray-600">© 2023 <Link href="https://www.ekofi.capital" className="text-gray-200 ml-1" target="_blank">Ekofi Capital</Link></p>
  </Layout>
)

export default IndexPage
